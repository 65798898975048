import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'semantic-ui-react';

import {
  LINK_ARIDHIA_KB,
  LINK_ARIDHIA_CONTACT_SD,
  LINK_ARIDHIA_PRIVACY_POLICY,
  LINK_LCC_FAIR,
  LINK_LCC_WORKSPACE_AUS_EAST,
  LINK_LCC_TERMS_OF_USE,
  LINK_LCC_WIKI,
} from 'env-create-react-app';

import defaultUserIcon from '../images/default-user-icon.png';
import lccLogo from '../images/LCC.svg';
import FAIRLogo2020White from '../images/FAIRLogo2020-white.svg';
import workspaceLogo2020White from '../images/workspacesLogo2020-white.svg';

const getUserInitials = (name) => {
  const parts = name.toUpperCase().split(' ');
  if (parts.length > 1) {
    return `${parts[0].charAt(0)}${parts[1].charAt(0)}`;
  }
  return `${parts[0].charAt(0)}`;
};

const avatarBackgroundColour = (name) => {
  const colours = [
    '#E9421E',
    '#B6271B',
    '#850F19',
    '#6E1932',
    '#562150',
    '#2C2A4C',
    '#0C3247',
    '#1B5A72',
    '#2C88A2',
    '#34867A',
    '#3C8556',
    '#5B8C41',
    '#7E922F',
    '#9EAE02',
    '#BE8C00',
    '#995F19',
  ];

  const vowels = (name.match(/[aeiou]/gi) || []).length;
  const index = (name.length + vowels) % colours.length;

  return colours[index];
};

const userName = (loggedIn, name) => {
  if (loggedIn && name) {
    const userInitials = getUserInitials(name);
    return (
      <>
        {name}
        <img
          id="avatar"
          src={defaultUserIcon}
          style={{ backgroundColor: avatarBackgroundColour(name) }}
          alt="User profile"
          className="ui mini right floated image"
          size="mini"
        />
        <div className="avatar-initials">{userInitials}</div>
        <div className="meta" />
      </>
    );
  }
  return (
    <>
      Please sign in
      <img id="avatar" src={defaultUserIcon} alt="User profile" className="ui mini right floated image" size="mini" style={{ backgroundColor: 'rgb(102, 102, 102)' }} />
      <div className="avatar-initials" />
      <div className="meta" />

    </>
  );
};

const Header = ({
  loggedIn, name, signIn, signOut,
}) => (
  <div id="header" className="semantic">
    <div className="ui inverted nav fixed menu">
      <div className="ui container">
        <a href="/" className="header item"><img src={lccLogo} alt="LCC Logo" className="ui image logo" id="portalLogo" /></a>
        <a href={LINK_LCC_FAIR} target="_blank" rel="noreferrer" className="item">
          <img src={FAIRLogo2020White} alt="" className="icon" />
          {' '}
          Data Services
        </a>
        <a href={LINK_LCC_WORKSPACE_AUS_EAST} target="_blank" rel="noreferrer" className="item">
          <img src={workspaceLogo2020White} alt="" className="icon" />
          Workspaces
          {' '}
        </a>
        <a href={LINK_LCC_WIKI} target="_blank" rel="noreferrer" className="item">
          LCC Wiki
        </a>
        <div className="right menu">
          <div className="item help">
            <a className="knowledgebase" href={LINK_ARIDHIA_KB} target="_blank" rel="noopener noreferrer" data-tooltip="Visit the Aridhia DRE Knowledge Base" data-variation="mini" data-position="bottom center">
              <span className="visuallyhidden">Visit the Aridhia DRE Knowledge Base</span>
              <i aria-hidden="true" className="question circle large icon" />
            </a>
          </div>
          <div className="ui simple dropdown item">
            <div id="user_name_display" className="header">
              {userName(loggedIn, name)}
            </div>
            <div className="menu">
              <a href={LINK_ARIDHIA_PRIVACY_POLICY} target="_blank" rel="noopener noreferrer" role="option" aria-selected="true" className="item">
                <i aria-hidden="true" className="shield icon" />
                Privacy
              </a>
              <a href={LINK_LCC_TERMS_OF_USE} target="_blank" rel="noopener noreferrer" role="option" aria-selected="false" className="item">
                <i aria-hidden="true" className="handshake solid icon" />
                Terms &amp; conditions
              </a>
              <a href={LINK_ARIDHIA_CONTACT_SD} target="_blank" rel="noopener noreferrer" role="option" aria-selected="false" className="item">
                <i aria-hidden="true" className="heart icon" />
                Feedback
              </a>
              <div className="divider" />
              <Button id="signin_link" role="option" aria-selected="false" className={((loggedIn) ? 'item hidden' : 'item')} onClick={signIn}>
                <i aria-hidden="true" className="sign in icon" />
                Sign in
              </Button>
              <Button id="signout_link" role="option" aria-selected="false" className={((loggedIn) ? 'item' : 'item hidden')} onClick={signOut}>
                <i aria-hidden="true" className="sign out icon" />
                Sign out
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

Header.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  signIn: PropTypes.func.isRequired,
  signOut: PropTypes.func.isRequired,
};

export default Header;
