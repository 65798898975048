/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {
  Container,
  Segment,
  Grid,
  Header,
  Card,
  Icon,
  Divider,
} from 'semantic-ui-react';
import {
  LINK_LCC_FAIR,
  LINK_LCC_FAIR_DATASETS,
  LINK_ARIDHIA_CONTACT_SD,
  LINK_ARIDHIA_SHINY_APPS,
  LINK_ARIDHIA_LEARNING_CENTRE,
  LINK_LCC_WEBSITE,
  LINK_EMBED_LCC_OVERVIEW_VIDEO,
  LINK_ARIDHIA_KB,
  LINK_LCC_LMS_WS_FUNDAMENTALS,
  LINK_LCC_LMS_WS_ADVANCED,
  LINK_LCC_LMS_FAIR,
  ENDPOINT_SECURITY,
  ENDPOINT_REQUEST_WORKSPACE,
} from 'env-create-react-app';

import FAIRLogo2020White from '../images/FAIRLogo2020-white.svg';
import FAIRLogo2020Green from '../images/fair-logo-green.svg';
import workspacesLogo2020Coloured from '../images/workspaces-logo-colour.svg';
import poweredByAridhia from '../images/AridhiaDRE-PoweredBy.svg';
import WSFundamentals from '../images/btn_ws.jpg';
import WSAdvanced from '../images/btn_ws_advanced.jpg';
import FAIRStandard from '../images/btn_fair.jpg';

const Content = () => (
  <div className="portal page content">
    <Container>
      <Segment className="portal-elements">
        <Header as="h2">Getting Started</Header>

        <Grid stackable columns="equal">
          <Grid.Column>
            <Card fluid>
              <Card.Header className="content">
                <Icon name="cog" size="large" className="right floated blue" />
                <span className="workspace-name">Introduction</span>
              </Card.Header>
              <Card.Content className="hero-content">
                <Card.Description className="hero-child">
                  <p>
                    <strong>
                      Welcome to the LCC Digital Research Environment, a place where modern
                      {' '}
                      data science collaboration happens – safely, securely and effectively.
                    </strong>
                  </p>
                  <p>
                    This platform aims to accelerate new treatment innovations by bridging the gap
                    {' '}
                    between researchers and a clinician-led, liver cancer biorepository.
                    {' '}
                    Containing detailed patient information, this repository is further enriched by
                    {' '}
                    the inclusion of genomic, clinical, and imaging information, and the DRE enables
                    {' '}
                    the discovery and request of these data in an efficient, cloud-based ecosystem.
                    {' '}
                    Query patient-level records and integrate them with
                    {' '}
                    existing research data. Leverage not only data, but the derived knowledge and
                    {' '}
                    results of other users. Research teams can take advantage of in-built
                    {' '}
                    analytical tools while utilising high-performance cloud computing to analyse
                    {' '}
                    the world's most comprehensive, all-stage liver cancer dataset.
                  </p>
                  <p>
                    <a className="ui purple button lcc" href={LINK_LCC_LMS_WS_FUNDAMENTALS} target="_blank" rel="noreferrer">
                      <span><img src={WSFundamentals} alt="Workspaces Fundamentals" className="ui image" /></span>
                      <div>Introduction to Workspaces</div>
                    </a>
                    <a className="ui purple button lcc" href={LINK_LCC_LMS_FAIR} target="_blank" rel="noreferrer">
                      <span><img src={FAIRStandard} alt="FAIR Standard" className="ui image" /></span>
                      <div>Using FAIR Data Services</div>
                    </a>
                    <a className="ui purple button lcc" href={LINK_LCC_LMS_WS_ADVANCED} target="_blank" rel="noreferrer">
                      <span><img src={WSAdvanced} alt="Workspaces Advanced" className="ui image" /></span>
                      <div>Workspaces Advanced Learning</div>
                    </a>
                  </p>
                </Card.Description>
                <Card.Description className="hero-child">
                  <iframe
                    title="ICODA Workspace Video"
                    width="100%"
                    height="400"
                    src={LINK_EMBED_LCC_OVERVIEW_VIDEO}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </Card.Description>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid>

        <Divider />

        <Header as="h2">Quick Start</Header>
        <Grid stackable columns="equal">
          <Grid.Column>
            <Card fluid>
              <Card.Header className="content">
                <img
                  src={FAIRLogo2020Green}
                  alt="FAIR Logo"
                  aria-hidden="true"
                  className="right floated icon"
                />
                <a
                  className="workspace-name"
                  href={LINK_LCC_FAIR}
                  target="_blank"
                  rel="noreferrer"
                >
                  Find Data
                </a>
              </Card.Header>
              <Card.Content>
                <Card.Description>
                  <ol>
                    <li>
                      Go to FAIR Data Services
                      <a
                        href={LINK_LCC_FAIR}
                        className="ui button"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          alt="FAIR logo"
                          src={FAIRLogo2020White}
                          className="icon"
                          style={{ display: 'inline-block' }}
                        />
                        {' '}
                        Data Services
                      </a>
                    </li>
                    <li>
                      Review the existing datasets: Click 'Datasets' and select ‘All datasets’ in
                      the dropdown
                      <a
                        title="Datasets"
                        className="ui button aridhia-data-launcher"
                        href={LINK_LCC_FAIR_DATASETS}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i aria-hidden="true" className="database icon" />
                        Datasets
                      </a>
                    </li>
                    <li>Click on an individual dataset to review its metadata.</li>
                  </ol>
                </Card.Description>
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <Card fluid>
              <Card.Header className="content">
                <img
                  src={workspacesLogo2020Coloured}
                  alt="Workspaces Logo"
                  aria-hidden="true"
                  className="right floated icon"
                />
                <a className="workspace-name" href={ENDPOINT_REQUEST_WORKSPACE}>
                  Request a Workspace
                </a>
              </Card.Header>
              <Card.Content>
                <Card.Description>
                  <ol>
                    <li>
                      <strong>Request a Project Workspace</strong>
                      <a href={ENDPOINT_REQUEST_WORKSPACE} className="ui button">
                        Request a Workspace
                      </a>
                    </li>
                    <li>Once you have a workspace, make a data access request in FAIR.</li>
                  </ol>
                </Card.Description>
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <Card fluid>
              <Card.Header className="content">
                <img
                  src={FAIRLogo2020Green}
                  alt="FAIR Logo"
                  aria-hidden="true"
                  className="right floated icon"
                />
                <a
                  className="workspace-name"
                  target="_blank"
                  rel="noreferrer"
                  href={LINK_LCC_FAIR}
                >
                  Request Data
                </a>
              </Card.Header>
              <Card.Content>
                <Card.Description>
                  <p>
                    <strong>Now you are ready to request data.</strong>
                  </p>
                  <ol>
                    <li>Go back to FAIR</li>
                    <li>Navigate to the relevant dataset</li>
                    <li>
                      Request a dataset by clicking on the Request Icon
                      {' '}
                      <i aria-hidden="true" className="key icon" />
                      in the menubar.
                    </li>
                    <li>Fill in the form – you will receive feedback from the data owner.</li>
                  </ol>
                </Card.Description>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid>

        <Divider />

        <Header as="h2">Useful Information</Header>

        <Grid stackable columns="equal">
          <Grid.Column>
            <Card fluid>
              <Card.Header className="content">
                <Icon name="shield alternate" size="large" className="right floated blue" />
                <a className="workspace-name" href={ENDPOINT_SECURITY}>
                  Data security
                </a>
              </Card.Header>
              <Card.Content>
                <Card.Description>
                  <p>
                    Data can be highly sensitive: it can be at risk for re-identification even when
                    explicit personally identifiable information is removed. Find out more about the
                    measures that we use to keep data safe.
                  </p>
                </Card.Description>
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <Card fluid>
              <Card.Header className="content">
                <Icon name="question circle outline" size="large" className="right floated blue" />
                <a
                  className="workspace-name"
                  target="_blank"
                  rel="noreferrer"
                  href={LINK_ARIDHIA_CONTACT_SD}
                >
                  Need Help?
                </a>
              </Card.Header>
              <Card.Content>
                <Card.Description className="need-help">
                  <div>
                    <a href={LINK_ARIDHIA_CONTACT_SD} target="_blank" rel="noreferrer">
                      <i aria-hidden="true" className="life-ring x-large icon blue" />
                      Contact Support
                    </a>
                  </div>
                  <div>
                    <a href={LINK_LCC_WEBSITE} target="_blank" rel="noreferrer">
                      <i aria-hidden="true" className="home x-large icon blue" />
                      LCC Website
                    </a>
                  </div>
                  <div>
                    <a href={LINK_ARIDHIA_KB} target="_blank" rel="noreferrer">
                      <i aria-hidden="true" className="info circle x-large icon blue" />
                      Knowledge Base
                    </a>
                  </div>
                  <div>
                    <a href={LINK_ARIDHIA_SHINY_APPS} target="_blank" rel="noreferrer">
                      <i aria-hidden="true" className="code x-large icon blue" />
                      Shiny Apps
                    </a>
                  </div>
                  <div>
                    <a href={LINK_ARIDHIA_LEARNING_CENTRE} target="_blank" rel="noreferrer">
                      <i aria-hidden="true" className="graduation x-large icon blue" />
                      Learning Centre
                    </a>
                  </div>
                </Card.Description>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid>
      </Segment>
      <Segment className="portal-elements">
        <img src={poweredByAridhia} alt="Powered by Aridhia DRE" className="powered-by-aridhia" />
      </Segment>
    </Container>
  </div>
);

export default Content;
