import React from 'react';

import { Header, Button } from 'semantic-ui-react';

import workbenchLogo from '../images/lcc_sidebar_image.png';

function toggleOverview() {
  const element = document.getElementById('flex');
  if (element.classList.contains('overview-hidden')) {
    document.getElementById('overview-content').style.marginLeft = '1rem';
  } else {
    const contentWidth = document.getElementById('overview-content').offsetWidth - 56;
    document.getElementById('overview-content').style.marginLeft = `-${contentWidth}px`;
  }
  element.classList.toggle('overview-hidden');
}

const Overview = () => (
  <div className="overview">
    <Button
      type="button"
      aria-label="Toggle Overview"
      className="overview_toggle toggle left"
      onClick={toggleOverview}
    >
      <i className="arrow circle left icon" />
    </Button>
    <Button
      type="button"
      aria-label="Toggle Overview"
      className="overview_toggle toggle right"
      onClick={toggleOverview}
    >
      <i className="arrow circle right icon" />
    </Button>
    <div className="content" id="overview-content">
      <Header as="h2">Overview</Header>
      <img src={workbenchLogo} alt="Workbench Overview Graphic" className="ui image" />
      <p>
        The Liver Cancer Collaborative (LCC) includes clinicians and researchers from multiple
        institutions working together to address the increasing global burden of liver cancer. The
        LCC, led by Professors Peter Leedman and Nina Tirnitz-Parker, was awarded a Cancer Research
        Trust Grant in 2020 with co-funding generously donated by several local organisations. The
        goal of the LCC is to deliver a clinician-led liver cancer biorepository with integrated
        clinical, genomic and discovery research data to facilitate the development of new
        treatments for liver cancer.
      </p>
      <p>
        The LCC biospecimens and bio-data form part of the Perkins Cancer Biobank which was
        established in 2019 following extensive consultation with consumers, clinicians and
        researchers. Collection of laboratory-generated data was included within the biobank
        framework, with the data generated from the biospecimens (bio-data) comprising an important
        component of the biobank.
      </p>
    </div>
  </div>
);

export default Overview;
