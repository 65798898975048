/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useState } from 'react';
import {
  Container,
  Segment,
  Breadcrumb,
  Card,
  Form,
  Button,
  Message,
  Icon,
  Ref,
  Divider,
} from 'semantic-ui-react';
import axios from 'axios';

import {
  API_BASE,
  API_ENDPOINT_REQUEST_WORKSPACE,
  LINK_ARIDHIA_CONTACT_SD,
} from 'env-create-react-app';

import { msalObj } from '../auth/signin';

const RequestWorkspace = () => {
  const valuesOrig = {
    vm_linux: false,
    vm_windows: false,
    interest_1: false,
    interest_2: false,
    interest_3: false,
    interest_4: false,
    interest_5: false,
    interest_other: false,
  };

  const errorsOrig = {
    project_name: false,
    project_name_message: 'Please provide a name for your project',
    project_description: false,
    project_description_message: 'Please provide a description of your project',
    interest: false,
    interest_message: 'Please select at least one of the options',
    apply_data_science_template: false,
    apply_data_science_template_message: 'Please select one of the options',
    requesting_biospecimens_message: 'Please select one of the options',
    requesting_biospecimens: false,

  };

  const [errors, setErrors] = useState(errorsOrig);

  const [values, setValues] = useState(valuesOrig);

  const [submitDisabled, setSubmitDisabled] = useState(false);

  const formRef = useRef(null);

  const [message, setMessage] = useState({
    showMessage: false,
    header: '',
    message: '',
    isLoading: '',
    isError: false,
    isSuccess: false,
  });

  const onChange = (event, result) => {
    const { name, value } = result || event.target;
    setValues({ ...values, [name]: value });
    setErrors({ ...errors, [name]: false }); // set error to false after any value change
  };

  const onChangeCheckbox = (event, result) => {
    const { name, checked } = result || event.target;
    setValues({ ...values, [name]: checked });
    setErrors({ ...errors, [name]: false }); // set error to false after any value change
  };

  const onChangeInterest = (event, result) => {
    const { name, checked, value } = result || event.target;
    if (name === 'interest_other_text') {
      setValues({ ...values, [name]: value });
    } else {
      setValues({ ...values, [name]: checked });
    }
    setErrors({ ...errors, interest: false }); // set error to false after any value change
  };

  const hideMessage = (clearFields = true) => {
    setMessage({ showMessage: false });
    if (clearFields) {
      setValues(valuesOrig);
      setErrors(errorsOrig);
      formRef.current.reset();
    }
    setSubmitDisabled(false);
  };

  const handleSuccessMessage = () => {
    setMessage({
      showMessage: true,
      header: 'Message Sent',
      message:
        'Thank you for making the request which is now under review. You will be provided an invitation to your workspace on approval.',
      isLoading: false,
      isError: false,
      isSuccess: true,
    });
  };

  const handlefailIdTokenMessage = () => {
    setMessage({
      showMessage: true,
      header: 'Failed to retrieve ID Token',
      message: 'There was an error processing your request. (Authentication error).',
      isLoading: false,
      isError: false,
      isSuccess: true,
    });
    setSubmitDisabled(false);
  };

  const handleFailUnauthorizedMessage = () => {
    setMessage({
      showMessage: true,
      header: 'Unauthorized',
      message: 'Your token was unauthorized. Please try to log out and log back in again.',
      isLoading: false,
      isError: true,
      isSuccess: false,
    });
    setSubmitDisabled(false);
  };

  const handleGenericErrorMessage = () => {
    setMessage({
      showMessage: true,
      header: 'Error',
      message: 'Unfortunately there was an error processing your request.',
      isLoading: false,
      isError: true,
      isSuccess: false,
    });
    setSubmitDisabled(false);
  };

  const handleFormErrorMessage = () => {
    setMessage({
      showMessage: true,
      header: 'Please check the errors in the form',
      message: 'Please review and address the errors displayed above and resubmit your form.',
      isLoading: false,
      isError: false,
      isFormError: true,
      isSuccess: false,
    });
    setSubmitDisabled(false);
  };

  const displayMessageContent = () => {
    if (message.isLoading) {
      return (
        <Message icon>
          <Icon name="circle notched" loading />
          <Message.Content>
            <Message.Header>{message.header}</Message.Header>
            <p>{message.message}</p>
          </Message.Content>
        </Message>
      );
    }

    if (message.isError) {
      return (
        <Message negative onDismiss={() => hideMessage(false)}>
          <Message.Header>{message.header}</Message.Header>
          <p>{message.message}</p>
          <p>
            If you keep seeing this message, please
            {' '}
            <a href={LINK_ARIDHIA_CONTACT_SD} target="_blank" rel="noreferrer">
              contact the service desk
            </a>
            .
          </p>
        </Message>
      );
    }

    if (message.isFormError) {
      return (
        <Message negative onDismiss={() => hideMessage(false)}>
          <Message.Header>{message.header}</Message.Header>
          <p>{message.message}</p>
        </Message>
      );
    }

    if (message.isSuccess) {
      return (
        <Message positive onDismiss={() => hideMessage(true)}>
          <Message.Header>{message.header}</Message.Header>
          <p>{message.message}</p>
        </Message>
      );
    }

    return (
      <Message onDismiss={() => hideMessage(false)}>
        <Message.Header>{message.header}</Message.Header>
        <p>{message.message}</p>
      </Message>
    );
  };

  const onSubmit = (event) => {
    setSubmitDisabled(true);

    // parse interest form field
    let interest = '';
    if (values.interest_1) interest += 'Data Science - general, ';
    if (values.interest_2) interest += 'Bioinformatics/Biostatistics, ';
    if (values.interest_3) interest += 'Mathematics/Statistics, ';
    if (values.interest_4) interest += 'AI/Machine Learning, ';
    if (values.interest_5) interest += 'Clinical Outcomes, ';
    if (values.interest_other && values.interest_other_text) {
      interest += `Other: ${values.interest_other_text}, `;
    }
    interest = interest.replace(/,\s$/, '');

    // Form validation
    let formValid = true;
    let scrollToElement = '';
    const errorsUpdate = {};

    if (!values.project_name) {
      formValid = false;
      errorsUpdate.project_name = true;
      if (!scrollToElement) scrollToElement = 'form-input-project_name';
    }
    if (!values.project_description) {
      formValid = false;
      errorsUpdate.project_description = true;
      if (!scrollToElement) scrollToElement = 'form-input-project_description';
    }
    if (!interest) {
      formValid = false;
      errorsUpdate.interest = true;
      if (!scrollToElement) scrollToElement = 'form-input-interest';
    }

    if (!values.apply_data_science_template && (values.vm_linux || values.vm_windows)) {
      formValid = false;
      errorsUpdate.apply_data_science_template = true;
      if (!scrollToElement) scrollToElement = 'form-input-datasciencevm';
    }

    if (!values.apply_data_science_template && !values.vm_linux && !values.vm_windows) {
      values.apply_data_science_template = 'No VM was selected.';
    }

    if (!values.requesting_biospecimens) {
      formValid = false;
      errorsUpdate.requesting_biospecimens = true;
      if (!scrollToElement) scrollToElement = 'form-input-request-biospecimens';
    }

    if (!formValid) {
      setErrors({ ...errors, ...errorsUpdate }); // set all errors at once
      handleFormErrorMessage();
      document
        .getElementById(scrollToElement)
        .scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' });
      return;
    }

    setMessage({
      showMessage: true,
      header: 'Just one second',
      message: 'We are submitting your form.',
      isLoading: true,
      isError: false,
      isSuccess: false,
    });

    const account = msalObj.getAccount();
    if (typeof account.idToken === 'undefined' || account.idToken == null) {
      console.error('Failed to obtain idToken');
      handlefailIdTokenMessage();
    } else {
      // Information on the logged in account
      const givenName = account.idToken.given_name;
      const familyName = account.idToken.family_name;
      const email = account.idToken.emails && account.idToken.emails.length > 0
        ? account.idToken.emails[0]
        : 'Not Available';
      const userid = account.idToken.oid ? account.idToken.oid : 'Not Available';

      let data = values;

      data.timestamp = `${new Date().toISOString()}`;
      data.source_url = window.location.href;
      data.userid = userid;
      data.email = email;
      data.full_name = `${givenName} ${familyName}`;
      data.interest = interest;
      data = JSON.stringify(data);
      console.log(data.interest);

      axios
        .post(
          `${API_BASE}${API_ENDPOINT_REQUEST_WORKSPACE}`,

          data,
          {
            headers: {
              Authorization: `Bearer ${window.sessionStorage.getItem('msal.idtoken')}`,
              'Content-Type': 'application/json',
            },
          },
        )
        .then(() => {
          handleSuccessMessage();
        })
        .catch((error) => {
          if (error.response) {
            // Request made and server responded
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            if (error.response.status === 401) {
              handleFailUnauthorizedMessage();
            } else {
              handleGenericErrorMessage();
            }
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
            handleGenericErrorMessage();
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
            handleGenericErrorMessage();
          }
        });
      event.preventDefault();
    }
  };
  return (
    <div className="portal page extra content">
      <Container>
        <Segment className="portal-elements">
          <Breadcrumb>
            <Breadcrumb.Section href="/">Home</Breadcrumb.Section>
            <Breadcrumb.Divider icon="right angle" />
            <Breadcrumb.Section active>Request a Workspace</Breadcrumb.Section>
          </Breadcrumb>
        </Segment>
        <Card fluid>
          <Card.Header className="content">
            <Icon name="exclamation circle" size="large" className="right floated blue" />
            <span className="workspace-name">Request a Workspace</span>
          </Card.Header>
          <Card.Content>
            <Card.Description>
              <p>
                This form can be used to request a workspace. Response will be via email to your
                registered user account.
              </p>
              <p>Please allow 24 hours for your request to be processed.</p>
            </Card.Description>
          </Card.Content>
          <Card.Content>
            <Ref innerRef={formRef}>
              <Form id="form_request_workspace" onSubmit={onSubmit}>
                <Form.Field
                  required
                  id="form-input-project_name" // allows hook to scroll to in case of error
                  style={{ scrollMarginTop: '5rem' }}
                >
                  <label>Project Name</label>
                  <Form.Input
                    label=""
                    name="project_name"
                    placeholder="Project Name"
                    onChange={onChange}
                    error={
                      errors.project_name
                        ? { content: errors.project_name_message, pointing: 'above' }
                        : false
                    }
                  />
                </Form.Field>
                <Form.Field
                  required
                  id="form-input-project_description" // allows hook to scroll to in case of error
                  style={{ scrollMarginTop: '5rem' }} // offset scroll to accomodate header
                >
                  <label>Project Description</label>
                  <Form.TextArea
                    label=""
                    name="project_description"
                    placeholder="Please describe your project"
                    onChange={onChange}
                    rows="3"
                    error={
                      errors.project_description
                        ? { content: errors.project_description_message, pointing: 'above' }
                        : false
                    }
                  />
                </Form.Field>
                <Form.Field>
                  <Form.TextArea
                    label="Additional Users"
                    name="additional_users"
                    placeholder="Please provide email addresses of other users who should be invited to the workspace"
                    onChange={onChange}
                    rows="3"
                  />
                </Form.Field>
                <Divider />
                <Form.Group className="grouped">
                  <h5>Do you require a virtual machine?</h5>
                  <Form.Field>
                    <Form.Checkbox
                      id="vm_windows"
                      name="vm_windows"
                      label="Windows Virtual Machine"
                      onClick={onChangeCheckbox}
                      checked={values.vm_windows}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Form.Checkbox
                      id="vm_linux"
                      name="vm_linux"
                      label="Linux Virtual Machine"
                      onClick={onChangeCheckbox}
                      checked={values.vm_linux}
                    />
                  </Form.Field>
                </Form.Group>
                {values.vm_linux || values.vm_windows ? (
                  <Form.Group className="grouped">
                    <Form.Field // form input allows for error tag to be grouped
                      id="form-input-datasciencevm" // allows hook to scroll to in case of error
                      style={{ scrollMarginTop: '5rem' }} // offset scroll to accomodate header>
                    >
                      <label>
                        <p>
                          Do you a require a Data Science template to be applied to your Virtual
                          Machine? The Data Science template includes the most common tools used in
                          Data Science Workflows i.e.
                          <span className="required">*</span>
                        </p>
                      </label>
                      <ul>
                        <li>
                          <a href="https://jupyter.org/" target="_blank" rel="noreferrer">
                            Jupyter Notebook
                          </a>
                        </li>
                        <li>
                          <a href="https://www.rstudio.com/" target="_blank" rel="noreferrer">
                            RStudio
                          </a>
                        </li>
                        <li>
                          <a href="https://code.visualstudio.com/" target="_blank" rel="noreferrer">
                            Visual Studio Code
                          </a>
                        </li>
                        <li>
                          <a href="https://julialang.org/" target="_blank" rel="noreferrer">
                            Julia
                          </a>
                        </li>
                        <li>
                          <a href="https://cran.r-project.org/" target="_blank" rel="noreferrer">
                            CRAN-R
                          </a>
                        </li>
                      </ul>
                      <p>
                        For more information please reference our Knowledge Base article
                        {' '}
                        <a href="https://knowledgebase.aridhia.io/article/using-a-data-science-virtual-machine/" target="_blank" rel="noreferrer">Using a Data Science Virtual Machine – Aridhia DRE Workspaces Knowledge Base</a>
                      </p>
                      <Form.Input
                        required
                        className="form-input-stack-items"
                        error={
                          errors.apply_data_science_template
                            ? {
                              content: errors.apply_data_science_template_message,
                              pointing: 'above',
                            }
                            : false
                        }
                        input={
                          <>
                            <Form.Radio
                              label="Yes, apply the Data Science template to the virtual machine."
                              name="apply_data_science_template"
                              value="Yes"
                              checked={values.apply_data_science_template === 'Yes'}
                              onChange={onChange}
                            />
                            <Form.Radio
                              label="No, I will install my own tools on the virtual machine."
                              name="apply_data_science_template"
                              value="No"
                              checked={values.apply_data_science_template === 'No'}
                              onChange={onChange}
                            />
                          </>
                        }
                      />
                    </Form.Field>
                  </Form.Group>
                ) : (
                  ''
                )}
                <Divider />
                <Form.Group className="grouped">
                  <Form.Field
                    id="form-input-interest" // allows hook to scroll to in case of error
                    style={{ scrollMarginTop: '5rem' }} // offset scroll to accomodate header>
                  >
                    <Form.Input // form input allows for error tag to be grouped
                      label="What is your area of interest? Check all that apply."
                      required
                      className="form-input-stack-items"
                      error={
                        errors.interest
                          ? { content: errors.interest_message, pointing: 'above' }
                          : false
                      }
                      input={
                        <>
                          <Form.Checkbox
                            name="interest_1"
                            label="Data Science - general"
                            onClick={onChangeInterest}
                            checked={values.interest_1}
                          />
                          <Form.Checkbox
                            name="interest_2"
                            label="Bioinformatics/Biostatistics"
                            onClick={onChangeInterest}
                            checked={values.interest_2}
                          />
                          <Form.Checkbox
                            name="interest_3"
                            label="Mathematics/Statistics"
                            onClick={onChangeInterest}
                            checked={values.interest_3}
                          />
                          <Form.Checkbox
                            name="interest_4"
                            label="AI/Machine Learning"
                            onClick={onChangeInterest}
                            checked={values.interest_4}
                          />
                          <Form.Checkbox
                            name="interest_5"
                            label="Clinical Outcomes"
                            onClick={onChangeInterest}
                            checked={values.interest_5}
                          />
                          <Form.Checkbox
                            name="interest_other"
                            label="Other"
                            onClick={onChangeInterest}
                            checked={values.interest_other}
                          />
                          <Form.Input
                            label=""
                            className={values.interest_other ? '' : 'hidden'}
                            name="interest_other_text"
                            placeholder="Other - Please Specify"
                            onChange={onChangeInterest}
                          />
                        </>
                      }
                    />
                  </Form.Field>
                </Form.Group>
                <Divider />

                <Form.Group className="grouped">
                  <Form.Field // form input allows for error tag to be grouped
                    id="form-input-request-biospecimens" // allows hook to scroll to in case of error
                    style={{ scrollMarginTop: '5rem' }}

                  >

                    <Form.Input
                      label="Will you be requesting biospecimens?"
                      required
                      className="form-input-stack-items"
                      error={
                          errors.requesting_biospecimens
                            ? {
                              content: errors.requesting_biospecimens_message,
                              pointing: 'above',
                            }
                            : false
                        }
                      input={
                        <>
                          <Form.Radio
                            label="Yes, I will be requesting biospecimens."
                            name="requesting_biospecimens"
                            value="Yes"
                            checked={values.requesting_biospecimens === 'Yes'}
                            onChange={onChange}
                          />
                          <Form.Radio
                            label="No, I will not be requesting biospecimens."
                            name="requesting_biospecimens"
                            value="No"
                            checked={values.requesting_biospecimens === 'No'}
                            onChange={onChange}
                          />
                        </>
                        }
                    />
                  </Form.Field>
                </Form.Group>
                <Divider />

                <Form.Field>
                  <Form.TextArea
                    label="Any other requirements?"
                    name="other_requirements"
                    placeholder="Please provide any additional details to help us set up your workspace"
                    onChange={onChange}
                    rows="3"
                  />
                </Form.Field>
                <Divider />
                <Form.Field>{message.showMessage ? displayMessageContent() : ''}</Form.Field>
                <div>
                  <Button
                    id="form_request_workspace_submit"
                    type="submit"
                    disabled={submitDisabled}
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            </Ref>
          </Card.Content>
        </Card>
      </Container>
    </div>
  );
};

export default RequestWorkspace;
